import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Layout } from '../components/Layout';

const StyledDiv = styled.div`
  width: 300px;
  height: 300px;
  background-color: blue;
`;

const StyledDivMotion = motion.custom(StyledDiv);
interface Props {
  pageContext: any;
}

const Index = ({ pageContext }: Props) => {
  return (
    <Layout>
      <StyledDivMotion initial={{ opacity: 0 }} animate={{ opacity: 1 }} />
    </Layout>
  );
};

export default Index;
