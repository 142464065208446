import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
`;

const Footer = () => <StyledFooter>&copy; 2021 Adam Webster</StyledFooter>;

export default Footer;
