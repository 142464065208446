import React, { ReactNode } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Footer } from '../Footer';
import { Header } from '../Header';

const GlobalStyles = createGlobalStyle`
    body, html{
  font-family: ${({ theme }: any) => theme.fontFamily};
  color: ${({ theme }: any) => theme.text};
  line-height: 1.6rem;
  background-color: ${({ theme }: any) => theme.background};
  font-size: 100%;
  margin: 0;
}
`;

const StyledLayout = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;
type Props = {
  children: ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <StyledLayout>
      <GlobalStyles />
      <Header />
      {children}
      <Footer />
    </StyledLayout>
  );
};

export default Layout;
