import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.primary};
`;

const Header = ({}) => <StyledHeader>Header</StyledHeader>;

export default Header;
